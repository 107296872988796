<template>
  <div
    class="flex p-1 m-0 mt-4 mb-4 pt-3 pb-3 items-center"
    @click="OpenDetail"
  >
    <div class="vx-col md:w-1/12 text-center noMinWidth">{{ customer.id }}</div>
    <div class="vx-col md:w-2/12 text-center companyMinWidth">
      {{ customer.name }}
    </div>
    <div class="vx-col md:w-1/12 text-center totalOrdersMinWidth">
      {{ customer.nokta_market_order_count }}
    </div>
    <div
      class="vx-col md:w-2/12 text-center goToMinWidth"
      style="color: #117fed"
    >
      {{ customer.purschased_credit_total }}
    </div>
    <div
      class="vx-col md:w-1/12 text-center creditMinWidth"
      style="color: #ed6e69"
    >
      {{
        Number.isInteger(
          customer.purschased_credit_total - customer.nokta_market_credit
        )
          ? customer.purschased_credit_total - customer.nokta_market_credit
          : (
              customer.purschased_credit_total - customer.nokta_market_credit
            ).toFixed(2)
      }}
    </div>
    <div
      class="vx-col md:w-2/12 text-center goToMinWidth"
      style="color: #28c76f"
    >
      {{
        Number.isInteger(parseFloat(customer.nokta_market_credit))
          ? parseFloat(customer.nokta_market_credit)
          : parseFloat(customer.nokta_market_credit).toFixed(2)
      }}
    </div>
    <div class="vx-col md:w-1/12 text-center countryMinWidth">
      {{ customer.country != "" ? customer.country : "-" }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  methods: {
    OpenDetail() {
      this.$router
        .push({
          path: "/nokta_admin_customer/" + this.customer.id + "/",
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
