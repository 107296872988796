<template>
  <div>
    <div class="BgWhiteBS p-3 mt-2">
      <div class="vx-row filterBy w-full">Filter By</div>
      <div class="vx-row w-full">
        <div class="vx-row titles mt-6">
          <div class="vx-row items-center mr-4">
            Company <vs-input class="ml-2" v-model="search" />
          </div>
          <vs-button
            class="ml-2"
            @click="GetCompanyList"
            type="filled"
            icon="filter_alt"
          ></vs-button>
        </div>
        <div class="grayLine ml-2 mr-2 md:block hidden"></div>
        <div class="vx-row titles">
          <div class="vx-row items-center mr-4 mt-6">Total Orders</div>
          <div class="vx-row items-center mr-4">
            <div class="vx-col text-center">
              <div>Min Order</div>
              <vs-input
                class="ml-2"
                v-model="minOrder"
                style="max-width: 70px"
              />
            </div>

            <div class="mt-6">-</div>

            <div class="vx-col text-center">
              <div>Max Order</div>
              <vs-input
                class="ml-2"
                v-model="maxOrder"
                style="max-width: 70px"
              />
            </div>
          </div>
          <vs-button
            class="mt-6"
            type="filled"
            icon="filter_alt"
            @click="GetCompanyList"
          ></vs-button>
        </div>
        <div class="grayLine ml-2 mr-2 md:block hidden"></div>
        <div class="vx-row titles mt-6">
          <div class="vx-col mt-2">Country</div>
          <div>
            <v-select
              style="min-width: 150px"
              class="mr-4 w-full"
              v-model="selectedCountry"
              @input="GetCompanyList"
              placeholder="All"
              label="name"
              :options="countries"
            ></v-select>
          </div>
        </div>
      </div>
    </div>

    <div style="overflow: auto">
      <div
        class="
          flex
          BgWhiteBS
          setBackgroundWidth
          p-1
          m-0
          mt-4
          mb-4
          items-center
          orderBar
          pt-3
          pb-3
        "
      >
        <div class="w-full md:w-1/12 text-center noMinWidth">No</div>
        <div class="w-full md:w-2/12 text-center companyMinWidth">Company</div>
        <div class="w-full md:w-1/12 text-center totalOrdersMinWidth">
          Number of Orders
        </div>
        <div class="w-full md:w-2/12 text-center goToMinWidth">
          Purchased Credit
        </div>
        <div class="w-full md:w-1/12 text-center creditMinWidth">
          Used Credit
        </div>
        <div class="w-full md:w-2/12 text-center goToMinWidth">
          Remaning Credit
        </div>
        <div class="w-full md:w-1/12 text-center countryMinWidth">Country</div>
      </div>

      <div
        class="BgWhiteBS setBackgroundWidth"
        v-for="customer in customers"
        :key="customer.id"
      >
        <customer-row
          class="customerRow"
          :customer="customer"
          @SelectCustomer="SelectCustomer"
          @GoToOrders="$emit('GoToOrders')"
          @GoToCredits="$emit('GoToCredits')"
        />
      </div>
      <div class="m-2 my-8">
        <vs-pagination
          :max="7"
          :total="totalPage"
          v-model="currentPage"
          @change="HandleChangePage(currentPage)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";

import vSelect from "vue-select";

import customerRow from "./ListRow.vue";

export default {
  data() {
    return {
      totalPage: 1,
      currentPage: 1,
      selectedCountry: null,
      minOrder: null,
      maxOrder: null,
      search: null,
      customers: [],
      countries: [],
    };
  },
  methods: {
    GetCompanyList() {
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });

      var params = {};
      if (this.selectedCountry) {
        params.country = this.selectedCountry.id;
      }
      if (this.minOrder) {
        params.min_order = this.minOrder;
      }
      if (this.maxOrder) {
        params.max_order = this.maxOrder;
      }

      if (this.search) {
        params.search = this.search;
      }

      if (this.currentPage != 1) {
        params.page = this.currentPage;
      }

      this.$router.push({ query: params }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
      Api.get(
        API.BASEURL + API.NOKTA_MARKET_PROVIDER_CUSTOMER,
        this.HandleGetCompanyList,
        params
      );
    },
    HandleGetCompanyList(status, data) {
      this.$vs.loading.close();

      if (status == 200) {
        this.customers = data.results;
        this.totalPage = Math.ceil(data.count / 50);
        this.countries = [];
        if (data.country_list.length > 0) {
          data.country_list.forEach((element) => {
            this.countries.push({
              id: element.country,
              name: element.country == "" ? "-" : element.country,
            });
          });
        }
      }
    },
    HandleChangePage(page) {
      this.currentPage = page;
      //GET DATA FROM API
      this.GetCompanyList();
    },
    SelectCustomer(customer) {
      this.$emit("ChangeCustomer", customer);
    },
  },
  components: {
    vSelect,
    customerRow,
  },
  created() {
    if (this.$route.query.min_order) {
      this.minOrder = this.$route.query.min_order;
    }
    if (this.$route.query.max_order) {
      this.maxOrder = this.$route.query.max_order;
    }
    if (this.$route.query.search) {
      this.search = this.$route.query.search;
    }
    if (this.$route.query.country) {
      this.selectedCountry = {
        id: this.$route.query.country,
        name: this.$route.query.country,
      };
    }
  },
};
</script>

<style lang="scss">
@media (max-width: 950px) {
  .setBackgroundWidth {
    width: fit-content !important;
  }
}
.setBackgroundWidth {
  width: auto;
}

.noMinWidth {
  min-width: 65px;
  max-width: 200px;
}
.companyMinWidth {
  min-width: 200px;
}
.totalOrdersMinWidth {
  min-width: 120px;
}
.goToMinWidth {
  min-width: 150px;
}
.creditMinWidth {
  min-width: 80px;
}
.countryMinWidth {
  min-width: 110px;
}
.customerRow {
  cursor: pointer;
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0px 3px 10px 0px #ccc3;
    transition: all 0.5s;
    font-weight: 600;
  }
}
</style>

<style scoped>
.vx-row {
  padding: 0px;
  margin: 0px;
}
.titles {
  font-weight: 500;
  color: #434343;
}

.grayLine {
  border: 1px solid #dedede;
}
.filterBy {
  color: #434343;
  font-weight: 900;
}
.orderBar {
  font-weight: 600;
  color: #626262;
}
</style>
