<template>
  <div>
    <Transition name="fade" mode="out-in">
      <component
        :is="activeComponent"
        :selectedCustomer="selectedCustomer"
        @backList="BackList"
        @ChangeCustomer="ChangeCustomer"
        @GoToOrders="GoToOrders"
        @GoToCredits="GoToCredits"
        @backCustomer="BackCustomer"
        @OpenOrderDetail="OpenOrderDetail"
        @backOrders="BackOrders"
      ></component>
    </Transition>
  </div>
</template>

<script>
import customerList from "./components/List.vue";
import customerOrderList from "./components/OrderList.vue";

export default {
  data() {
    return {
      activeComponent: "customerList",
      selectedCustomer: null,
      showDetail: false,
    };
  },

  methods: {
    BackOrders() {
      this.activeComponent = "customerOrderList";
    },
    OpenOrderDetail() {
      this.activeComponent = "customerOrderDetail";
    },
    BackCustomer() {
      this.activeComponent = "customerDetail";
    },
    BackList() {
      this.selectedCustomer = null;
      this.activeComponent = "customerList";
    },
    GoToOrders() {
      this.activeComponent = "customerOrderList";
    },
    GoToCredits() {
      this.activeComponent = "customerCredit";
    },
    ChangeCustomer(customer) {
      this.selectedCustomer = customer;
      let params = {};
      params = JSON.parse(JSON.stringify(this.$route.query));
      params.customer = customer.id;
      this.$router.push({ query: params }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
  },
  computed: {
    query() {
      return this.$route.query;
    },
  },
  watch: {
    selectedCustomer(newValue) {
      if (newValue != null) {
        this.activeComponent = "customerDetail";
      } else {
        this.activeComponent = "customerList";
      }
    },
    query(newValue) {
      if (!newValue.hasOwnProperty("customer")) {
        this.selectedCustomer = null;
      }
    },
  },
  created() {
    if (this.query.customer) {
      this.selectedCustomer = {
        id: 1,
        company: "Company 1",
        numberOfOrders: "100",
        purchasedCredit: "500",
        usedCredit: "100",
        country: "USA",
      };
      this.activeComponent = "customerDetail";
    }
  },

  components: {
    customerList,
    customerOrderList,
  },
};
</script>

<style>
.BgWhiteBS {
  background-color: white;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
