<template>
  <div class="vx-row p-1 m-0 mt-4 mb-4 pt-3 pb-3 items-center">
    <div
      class="vx-col md:w-1/12 text-center orderId"
      @click="OpenOrderDetail(order)"
    >
      #{{ order.id }}
    </div>
    <div class="vx-col md:w-1/12 text-center">
      {{ GetRelativeTime(order.created) }}
    </div>

    <div class="vx-col md:w-2/12 text-center">{{ order.total_weight }}</div>
    <div class="vx-col md:w-1/12 ml-auto justify-center flex">
      <vs-chip :color="GetOrderStatusColor(order.order_status)">{{
        order.status_display
      }}</vs-chip>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  methods: {
    OpenOrderDetail(order) {
      this.$emit("OpenOrderDetail", order);
    },
    GetOrderStatusColor(status) {
      if (status == "P") return "warning";
      if (status == "R") return "success";
      if (status == "C") return "danger";
      if (status == "W") return "#389CA3";
      return "primary";
    },
    GetRelativeTime(value) {
      return moment(String(value)).format("DD-MM-YY");
    },
  },
};
</script>

<style scoped>
.orderId {
  color: #117fed;
  cursor: pointer;
}
</style>
